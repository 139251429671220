var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',_vm._g(_vm._b({staticClass:"elevation-1 tableClass",class:_vm.isStriped ? 'strip_table' : '',attrs:{"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('nodata'),"dense":"","show-select":_vm.showSelect,"hide-default-footer":_vm.hideDefaultFooter,"items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"0"},on:{"input":_vm.handleInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top")]},proxy:true},{key:"item",fn:function({ item, index }){return [_c('tr',{class:[
        _vm.isSelected(item) ? 'v-data-table__selected' : '',
        _vm.getRowClass(item, index),
      ],style:(_vm.getRowStyle),on:{"click":function($event){return _vm.rowClicked(item, index)}}},[(_vm.showSelect)?_c('td',{staticClass:"tableColClass text-center"},[_c('v-simple-checkbox',{attrs:{"value":_vm.isSelected(item)},on:{"click":function($event){return _vm.toggleSelection(item)}}})],1):_vm._e(),_vm._l((_vm.headers),function(header){return _c('td',{key:header.value,staticClass:"tableColClass",class:[
          header.colClass || '',
          header.align ? `text-${header.align}` : 'text-center',
        ]},[(_vm.$scopedSlots[`item.${header.value}`])?[_vm._t(`item.${header.value}`,null,{"item":item,"index":index})]:[_c('span',{class:_vm.getColumnClass(item, header),domProps:{"innerHTML":_vm._s(_vm.getCustomValue(item, header.value, index))}})]],2)})],2)]}},_vm._l((_vm.customHeaders),function(header){return {key:`item.${header.key}`,fn:function(slotProps){return [_vm._t(`item.${header.key}`,null,null,slotProps)]}}}),{key:`body.append`,fn:function({ items, headers }){return [(_vm.shouldShowTotals)?_c('tr',{staticClass:"totals_column text-center always-at-bottom"},_vm._l((headers),function(header,i){return _c('td',{key:i,staticClass:"tableColClass",class:[
          header.colClass || '',
          header.align ? `text-${header.align}` : 'text-center',
        ]},[(_vm.sumColumns.includes(header.keyValue))?_c('strong',[_vm._v(" "+_vm._s(_vm.customSums.hasOwnProperty(header.keyValue) ? _vm.customSums[header.keyValue] : _vm.calculateSum(header.value, items))+" ")]):_c('span',[_vm._v(" ")])])}),0):_vm._e(),_vm._t("body.append",null,null,{ items, headers })]}},{key:`item.data-table-expand`,fn:function(slotProps){return [_vm._t("item.data-table-expand",null,null,slotProps)]}},{key:`expanded-item`,fn:function(slotProps){return [_vm._t("expanded-item",null,null,slotProps)]}},{key:`footer`,fn:function(slotProps){return [_vm._t("footer",null,null,slotProps)]}}],null,true)},'v-data-table',{ ..._vm.$attrs, ..._vm.$props },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }