<template>
  <v-navigation-drawer
    :right="!getLanguage"
    :value="getDrawerState"
    app
    class="elevation-5 d-print-none"
  >
    <div v-if="getAuthentication">
      <v-list class="pb-0">
        <v-list-item class="pa-0">
          <v-list-item-content class="pa-0 justify-center text-center">
            <UserOptions
              :getOnlineStatus="getOnlineStatus"
              @accountLogout="logout"
            />

            <v-row
              class="ma-0 px-2 py-2 justify-center align-center text-start"
            >
              <!-- <div style="width: 203px"> -->
              <v-list-item-title
                class="font-weight-bold text-center"
                style="font-size: 0.9rem; white-space: unset"
              >
                {{ getCompanyData.name }}
              </v-list-item-title>
              <!-- <v-list-item-subtitle>
                  {{ $t("database") }} : {{ getDatabase }}
                </v-list-item-subtitle> -->
              <!-- </div> -->
              <!-- <v-spacer></v-spacer>
              <Notifications /> -->
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <!-- { icon: "mdi-home", title: this.$t("home"), link: "/", rules: true }, <Notifications /> -->
        <div class="d-flex align-center">
          <v-list-item
            :to="'/'"
            exact
            link
            :class="{ 'active-link': $route.path === '/' }"
          >
            <v-list-item-title class="">
              <v-icon class="mx-1" dense> mdi-home </v-icon>
              {{ $t("home") }}
            </v-list-item-title>
          </v-list-item>

          <div class="px-2">
            <Notifications />
          </div>
        </div>

        <template v-for="(menuItem, index) in menuItems">
          <v-list-group
            :key="index"
            class="text-start"
            v-if="menuItem.children && menuItem.children.length > 0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="">
                  <v-icon class="mx-1" dense> {{ menuItem.icon }} </v-icon>
                  {{ menuItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="(child, index) in menuItem.children">
              <v-list-group
                :key="index"
                class=""
                sub-group
                v-if="child.children"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-for="(children, i) in child.children">
                  <v-list-item
                    :to="children.link"
                    :key="i"
                    exact
                    :class="{ 'active-link': $route.path === children.link }"
                  >
                    <v-list-item-content class="px-4">
                      <v-list-item-title>
                        {{ children.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>

              <v-list-item
                :key="child.link"
                :to="child.link"
                exact
                v-else-if="!child.children"
                :class="{ 'active-link': $route.path === child.link }"
                class="mx-4"
              >
                <v-list-item-content>
                  <v-list-item-title> {{ child.title }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item
            :key="menuItem.index"
            :to="menuItem.link"
            exact
            link
            v-else-if="!menuItem.children"
            :class="{ 'active-link': $route.path === menuItem.link }"
          >
            <v-list-item-title class="">
              <v-icon class="mx-1" dense> {{ menuItem.icon }} </v-icon>
              {{ menuItem.title }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </div>

    <v-list nav dense>
      <v-list-item v-if="!getAuthentication" link to="/login">
        <v-list-item-title class="">
          <v-icon class="mx-1" dense> mdi-login </v-icon>
          {{ $t("auth.login") }}
        </v-list-item-title>
      </v-list-item>

      <DarkMode />
      <Language :langMenu="langMenu" @setLanguage="setLanguage($event)" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DarkMode from "./DrawerComponents/DarkMode.vue";
import Language from "./DrawerComponents/Language.vue";
import UserOptions from "./DrawerComponents/UserOptions.vue";
import Notifications from "./DrawerComponents/Notifications.vue";
export default {
  components: {
    DarkMode,
    Language,
    UserOptions,
    Notifications,
  },
  data: () => ({
    langMenu: [
      { id: 1, name: "EN" },
      { id: 2, name: "AR" },
    ],
  }),
  methods: {
    ...mapActions("darkToggler", ["loadDefaults"]),
    ...mapActions("User", ["logout"]),
    ...mapMutations("App", ["setLanguage", "toggleDrawerState"]),

    drawerAction() {
      this.toggleDrawerState();
    },

    filterMenu(items) {
      return items
        .filter(
          (item) =>
            item.rules &&
            (item.children === undefined || item.children.length > 0)
        )
        .map((item) => {
          if (item.children) {
            item.children = this.filterMenu(item.children);
          }
          return item;
        });
    },
  },
  computed: {
    // ...mapGetters("Company", ["getDatabase"]),
    ...mapGetters("User", ["getAuthentication"]),
    ...mapGetters("App", ["getDrawerState", "getOnlineStatus"]),

    menuItems() {
      let originalMenu = [
        {
          icon: "mdi-book-open-variant",
          title: this.$t("constructions"),
          children: [
            {
              icon: "mdi-alpha-c-box",
              title: this.$t("itemscat"),
              link: "/categories",
              rules: this.permissionChecker("category-view"),
            },
            {
              icon: "mdi-alpha-i-box",
              title: this.$t("items"),
              link: "/items",
              rules: this.permissionChecker("item-view"),
            },
            {
              icon: "mdi-face-agent",
              title: this.$t("employees"),
              link: "/employees",
              rules: this.permissionChecker("employee-view"),
            },
            {
              icon: "mdi-account-arrow-right",
              title: this.$t("clients"),
              link: "/clients",
              rules: this.permissionChecker("client-view"),
            },
            {
              icon: "mdi-account-arrow-left",
              title: this.$t("suppliers"),
              link: "/suppliers",
              rules: this.permissionChecker("supplier-view"),
            },
            {
              icon: "mdi-store-edit",
              title: this.$t("stores"),
              link: "/stores",
              rules: this.permissionChecker("store-view"),
            },
            {
              icon: "mdi-warehouse",
              title: this.$t("branches"),
              link: "/branches",
              rules: this.permissionChecker("branch-view"),
            },
            {
              icon: "mdi-alpha-d-circle-outline",
              title: this.$t("drawers"),
              link: "/drawers",
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.accounting_management
                ) && this.permissionChecker("drawer-view"),
            },
            {
              icon: "mdi-credit-card-marker",
              title: this.$t("mainaccounts"),
              link: "/mainaccounts",
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.accounting_management
                ) && this.permissionChecker("mainacc-view"),
            },
            {
              icon: "mdi-credit-card-multiple",
              title: this.$t("subaccounts"),
              link: "/subaccounts",
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.accounting_management
                ) && this.permissionChecker("subacc-view"),
            },
            {
              icon: "mdi-credit-card-multiple",
              title: this.$t("fixedassets"),
              link: "/fixedassets",
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.accounting_management
                ) &&
                this.companyPermissionChecker(
                  this.CompanyPermissions.advanced_excel_management
                ) &&
                this.permissionChecker("fixedassets-view"),
            },
          ],
          rules: true,
        },
        {
          icon: "mdi-book-minus-multiple",
          title: this.$t("salesmanagement"),
          children: [
            {
              icon: "",
              title: this.$t("sale"),
              link: "/sales",
              rules: this.permissionChecker("sale-view"),
            },
            {
              icon: "",
              title: this.$t("salesreturns"),
              link: "/salesreturns",
              rules: this.permissionChecker("salereturn-view"),
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("cashier"),
              link: "/cashier",
              rules: this.permissionChecker("cashier-create"),
            },
            {
              icon: "",
              title: this.$t("salereports"),
              children: [
                {
                  icon: "mdi-chart-line",
                  title: this.$t("itemsales"),
                  link: "/sales/itemsearch",
                  rules: this.permissionChecker("sale-itemsales"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("itemsales_grouped"),
                  link: "/sales/item-grouped",
                  rules: this.permissionChecker("sale-itemsales"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/sales/deleted",
                  rules: this.permissionChecker("sale-deleted_preview"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("invoicesprofits"),
                  link: "/invoicesprofits",
                  rules: this.permissionChecker("general-viewprofitandcost"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("itemsprofits"),
                  link: "/sales/itemprofits",
                  rules: this.permissionChecker("general-viewprofitandcost"),
                },
                // {
                //   icon: "mdi-chart-line",
                //   title: this.$t("stagnantitems"),
                //   link: "/stagnantitems",
                //   rules: true,
                // },
              ],
              rules: this.permissionChecker("sale-view"),
            },
            {
              icon: "",
              title: this.$t("salereturnreports"),
              children: [
                {
                  icon: "mdi-chart-line",
                  title: this.$t("salereturnsitems"),
                  link: "/salesreturns/itemsearch",
                  rules: this.permissionChecker("salereturn-itemreturns"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/salesreturns/deleted",
                  rules: this.permissionChecker("salereturn-deleted_preview"),
                },
              ],
              rules: this.permissionChecker("salereturn-view"),
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("cashierreports"),
              children: [
                {
                  icon: "mdi-network-pos",
                  title: this.$t("cashierdaily"),
                  link: "/cashierdaily",
                  rules: true,
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/cashiers/deleted",
                  rules: this.permissionChecker("cashier-deleted_preview"),
                },
              ],
              rules: this.permissionChecker("cashier-view"),
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("reservations"),
              children: [
                {
                  icon: "mdi-network-pos",
                  title: this.$t("clientreservations"),
                  link: "/reservations",
                  rules: true,
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/reservations/deleted",
                  rules: true,
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.reservation_management
                ) && this.permissionChecker("reservation-view"),
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("saleorders"),
              children: [
                {
                  icon: "mdi-network-pos",
                  title: this.$t("saleorder"),
                  link: "/saleorders",
                  rules: true,
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/saleorders/deleted",
                  rules: true,
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.order_management
                ) && this.permissionChecker("saleorder-view"),
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("salesagentreports"),
              children: [
                {
                  icon: "mdi-network-pos",
                  title: this.$t("salesagentscollections"),
                  link: "/salesagentscollections",
                  rules: this.permissionChecker("sale-saleagents_collection"),
                },
                {
                  icon: "mdi-network-pos",
                  title: this.$t("salesagentsclients"),
                  link: "/salesagentsclients",
                  rules: this.permissionChecker("sale-saleagents_clients"),
                },
                {
                  icon: "mdi-network-pos",
                  title: this.$t("salesagentsitems"),
                  link: "/salesagentsitems",
                  rules: this.permissionChecker("sale-saleagents_items"),
                },
                {
                  icon: "mdi-network-pos",
                  title: this.$t("salesagentcommission"),
                  link: "/salesagentcommission",
                  rules: this.permissionChecker("employee-calccommission"),
                },
              ],
              rules: this.permissionChecker([
                "sale-saleagents_collection",
                "sale-saleagents_clients",
                "sale-saleagents_items",
                "employee-calccommission",
              ]),
            },
          ],
          rules: this.companyPermissionChecker(
            this.CompanyPermissions.sale_management
          ),
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("purchasesmanagement"),
          children: [
            {
              icon: "",
              title: this.$t("purchase"),
              link: "/purchases",
              rules: this.permissionChecker("purchase-view"),
            },
            {
              icon: "",
              title: this.$t("purchasesreturns"),
              link: "/purchasesreturns",
              rules: this.permissionChecker("purchasereturn-view"),
            },
            {
              icon: "",
              title: this.$t("purchasereports"),
              children: [
                {
                  icon: "mdi-chart-line",
                  title: this.$t("itempurchases"),
                  link: "/purchases/itemsearch",
                  rules: this.permissionChecker("purchase-itempurchases"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("itempurchases_grouped"),
                  link: "/purchases/item-grouped",
                  rules: this.permissionChecker("purchase-itempurchases"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/purchases/deleted",
                  rules: this.permissionChecker("purchase-deleted_preview"),
                },
              ],
              rules: this.permissionChecker("purchase-view"),
            },
            {
              icon: "",
              title: this.$t("purchasereturnreports"),
              children: [
                {
                  icon: "mdi-chart-line",
                  title: this.$t("purchasereturnsitems"),
                  link: "/purchasesreturns/itemsearch",
                  rules: this.permissionChecker("purchasereturn-itemreturns"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/purchasesreturns/deleted",
                  rules: this.permissionChecker(
                    "purchasereturn-deleted_preview"
                  ),
                },
              ],
              rules: this.permissionChecker("purchasereturn-view"),
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("purchaseorders"),
              children: [
                {
                  icon: "mdi-network-pos",
                  title: this.$t("purchaseorder"),
                  link: "/purchaseorders",
                  rules: true,
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/purchaseorders/deleted",
                  rules: true,
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.order_management
                ) && this.permissionChecker("purchaseorder-view"),
            },
          ],
          rules: this.companyPermissionChecker(
            this.CompanyPermissions.purchase_management
          ),
        },
        {
          icon: "mdi-store",
          title: this.$t("storesmanagement"),
          children: [
            {
              icon: "mdi-store-marker",
              title: this.$t("itemstock"),
              link: "/storelog",
              rules: this.permissionChecker("store-viewqty"),
            },
            {
              icon: "mdi-store-search",
              title: this.$t("itemrecords"),
              link: "/store/report",
              rules: this.permissionChecker("store-searchdetail"),
            },
            {
              title: this.$t("inventory"),
              children: [
                {
                  title: this.$t("inventorycount"),
                  link: "/inventorycount",
                  rules: true,
                },
                {
                  title: this.$t("generalinventorycount"),
                  link: "/generalinventorycount",
                  rules: true,
                },
                {
                  title: this.$t("restock_alert"),
                  link: "/inventory/restock-alert",
                  rules: true,
                },
              ],
              rules: this.permissionChecker("inventorycount-view"),
            },
            {
              icon: "mdi-pound",
              title: this.$t("storein"),
              children: [
                {
                  icon: "mdi-pound",
                  title: this.$t("storein"),
                  link: "/storeins",
                  rules: this.permissionChecker("storein-view"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/storeins/deleted",
                  rules: true,
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.storeinout_management
                ) && this.permissionChecker("storein-view"),
            },
            {
              icon: "mdi-pound",
              title: this.$t("storeout"),
              children: [
                {
                  icon: "mdi-pound",
                  title: this.$t("storeout"),
                  link: "/storeouts",
                  rules: this.permissionChecker("storeout-view"),
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/storeouts/deleted",
                  rules: true,
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.storeinout_management
                ) && this.permissionChecker("storeout-view"),
            },
            {
              icon: "mdi-bank-transfer",
              title: this.$t("storetransfers"),
              children: [
                {
                  icon: "mdi-pound",
                  title: this.$t("transfers"),
                  link: "/storetransfers",
                  rules: true,
                },
                {
                  icon: "mdi-chart-line",
                  title: this.$t("deleted"),
                  link: "/storetransfers/deleted",
                  rules: true,
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.store_transfer_management
                ) && this.permissionChecker("transfer-view"),
            },
            {
              icon: "mdi-bank-transfer",
              title: this.$t("storereports"),
              rules: true,
              children: [
                {
                  icon: "mdi-pound",
                  title: this.$t("serials"),
                  link: "/serials",
                  rules: true,
                },
                {
                  icon: "mdi-pound",
                  title: this.$t("serialtransactions"),
                  link: "/serialtransactions",
                  rules: this.permissionChecker("store-searchdetail"),
                },
                {
                  icon: "mdi-pound",
                  title: this.$t("expiretransactions"),
                  link: "/expiretransactions",
                  rules: this.permissionChecker("store-searchdetail"),
                },
                {
                  icon: "mdi-pound",
                  title: this.$t("expirequantities"),
                  link: "/expirequantities",
                  rules: this.permissionChecker("store-searchdetail"),
                },
                {
                  icon: "mdi-currency-usd",
                  title: this.$t("pricedisplayscreen"),
                  link: "/pricedisplayscreen",
                  rules: true,
                },
              ],
            },
          ],
          rules: true,
        },
        {
          icon: "mdi-chart-bar-stacked",
          title: this.$t("accountsmanagements"),
          children: [
            {
              icon: "",
              title: this.$t("entriesreport"),
              link: "/entriesreport",
              rules: this.permissionChecker("entry-searchdetail"),
            },
            {
              icon: "",
              title: this.$t("freeentry"),
              link: "/entries",
              rules: this.permissionChecker("entry-view"),
            },
            {
              icon: "",
              title: this.$t("draweraction"),
              link: "/draweraction",
              rules: this.permissionChecker([
                "draweraction-deposit",
                "draweraction-withdraw",
                "draweraction-cashtransfer",
              ]),
            },
            {
              icon: "",
              title: this.$t("clientsreceivable"),
              link: "/clientsreceivable",
              rules: this.permissionChecker("general-clientsreceivable"),
            },
            {
              icon: "",
              title: this.$t("supplierspayable"),
              link: "/supplierspayable",
              rules: this.permissionChecker("general-supplierspayable"),
            },
            {
              icon: "",
              title: this.$t("ledger"),
              link: "/ledger",
              rules: this.permissionChecker("general-ledger"),
            },
            {
              icon: "mdi-bank-transfer",
              title: this.$t("finalbalancesheet"),
              link: "/finalbalancesheet",
              rules: this.permissionChecker("general-finalbalancesheet"),
            },
            {
              icon: "",
              title: this.$t("checknotes"),
              children: [
                {
                  icon: "",
                  title: this.$t("notesreceivable"),
                  link: "/notesreceivable",
                  rules: this.permissionChecker("receivable-view"),
                },
                {
                  icon: "",
                  title: this.$t("notespayable"),
                  link: "/notespayable",
                  rules: this.permissionChecker("payable-view"),
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.notes_management
                ) &&
                this.permissionChecker(["receivable-view", "payable-view"]),
            },
          ],
          rules: this.companyPermissionChecker(
            this.CompanyPermissions.accounting_management
          ),
        },
        {
          icon: "mdi-factory",
          title: this.$t("productionmanagement"),
          children: [
            {
              icon: "mdi-network-pos",
              title: this.$t("production_proportions"),
              link: "/production-proportions",
              rules: true,
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("free_productions"),
              link: "/productions/free",
              rules: true,
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("proportion_production"),
              link: "/productions/proportion",
              rules: true,
            },
          ],
          rules:
            this.companyPermissionChecker(
              this.CompanyPermissions.production_management
            ) && this.permissionChecker("production-view"),
        },
        {
          icon: "mdi-silverware-fork-knife",
          title: this.$t("restaurantmanagement"),
          children: [
            {
              icon: "",
              title: this.$t("tables"),
              link: "/restaurant/tables",
              rules: this.permissionChecker("restauranttable-view"),
            },
            {
              icon: "",
              title: this.$t("categories"),
              link: "/restaurant/categories",
              rules: this.permissionChecker("restaurantcat-view"),
            },
            {
              icon: "",
              title: this.$t("meals"),
              link: "/restaurant/meals",
              rules: this.permissionChecker("restaurantmeal-view"),
            },
            {
              icon: "",
              title: this.$t("order"),
              link: "/restaurant/orders",
              rules: this.permissionChecker("restaurantorder-view"),
            },
            {
              icon: "",
              title: this.$t("kitchen_screen"),
              link: "/restaurant/kitchenscreen",
              rules: this.permissionChecker("restaurantorder-kitchen_view"),
            },
            {
              icon: "",
              title: this.$t("reservations"),
              link: "/restaurant/reservations",
              rules: this.permissionChecker("restaurant_reservation-view"),
            },
            {
              icon: "",
              title: this.$t("order_reports"),
              children: [
                {
                  icon: "",
                  title: this.$t("orders"),
                  link: "/restaurant/orders/search",
                  rules: this.permissionChecker("restaurantorder-search"),
                },
                {
                  icon: "",
                  title: this.$t("orderprofits"),
                  link: "/restaurant/orderprofits",
                  rules: this.permissionChecker(
                    "restaurantorder-order_profits"
                  ),
                },
                {
                  icon: "",
                  title: this.$t("mealprofits"),
                  link: "/restaurant/mealprofits",
                  rules: this.permissionChecker("restaurantorder-meal_profits"),
                },
                // {
                //   icon: "",
                //   title: this.$t("addonprofits"),
                //   link: "/restaurant/addonprofits",
                //   rules: this.permissionChecker("restaurantorder-meal_profits"),
                // },
                {
                  icon: "",
                  title: this.$t("meal_transactions"),
                  link: "/restaurant/mealtransactions",
                  rules: this.permissionChecker(
                    "restaurantorder-meal_transactions"
                  ),
                },
                {
                  icon: "",
                  title: this.$t("recipe_transactions"),
                  link: "/restaurant/recipetransactions",
                  rules: this.permissionChecker(
                    "restaurantorder-recipe_transactions"
                  ),
                },
                // {
                //   icon: "",
                //   title: this.$t("addon_transactions"),
                //   link: "/restaurant/addontransactions",
                //   rules: this.permissionChecker(
                //     "restaurantorder-meal_transactions"
                //   ),
                // },
                {
                  icon: "",
                  title: this.$t("deletedorders"),
                  link: "/restaurant/orders/deleted",
                  rules: this.permissionChecker(
                    "restaurantorder-deleted_preview"
                  ),
                },
              ],
              // this.permissionChecker([
              //   "restaurantorder-order_profits",
              //   "restaurantorder-meal_profits",
              //   "restaurantorder-recipe_transactions",
              //   "restaurantorder-meal_transactions",
              //   "restaurantorder-deleted_preview",
              // ])
              rules: true,
            },
          ],
          rules:
            this.companyPermissionChecker(
              this.CompanyPermissions.restaurant_management
            ) &&
            this.permissionChecker([
              "restauranttable-view",
              "restaurantcat-view",
              "restaurantorder-view",
              "restaurantmeal-view",
              "restaurantorder-kitchen_view",
              "restaurant_reservation-view",
            ]),
        },
        {
          icon: "mdi-wrench",
          title: this.$t("maintenance"),
          children: [
            {
              icon: "mdi-network-pos",
              title: this.$t("maintenance"),
              link: "/maintenances",
              rules: true,
            },
            {
              icon: "mdi-network-pos",
              title: this.$t("maintenanceclients"),
              link: "/maintenanceclients",
              rules: true,
            },

            {
              icon: "mdi-chart-line",
              title: this.$t("deleted"),
              link: "/maintenances/deleted",
              rules: true,
            },
          ],
          rules:
            this.companyPermissionChecker(
              this.CompanyPermissions.maintenance_management
            ) && this.permissionChecker("maintenance-view"),
        },
        {
          icon: "mdi-dots-horizontal-circle-outline",
          title: this.$t("othermanagements"),
          children: [
            {
              icon: "mdi-dots-horizontal-outline",
              title: this.$t("cleanningmanagement"),
              children: [
                {
                  icon: "mdi-cog",
                  title: this.$t("invoices"),
                  link: "/cleanninginvoice",
                  rules: true,
                },
                {
                  icon: "mdi-cog",
                  title: this.$t("invoicescollection"),
                  link: "/invoicescollection",
                  rules: true,
                },
                {
                  icon: "mdi-cog",
                  title: this.$t("cleanningclients"),
                  link: "/cleanningclients",
                  rules: true,
                },
                {
                  icon: "mdi-cog",
                  title: this.$t("cleanningclientsreport"),
                  link: "/cleanningclientsreport",
                  rules: true,
                },
                {
                  icon: "mdi-cog",
                  title: this.$t("clientsreceivable"),
                  link: "/cleanningclientsreceivable",
                  rules: true,
                },
                {
                  icon: "mdi-cog",
                  title: this.$t("salesagentsreport"),
                  link: "/salesagentsreport",
                  rules: true,
                },
              ],
              rules:
                this.companyPermissionChecker(
                  this.CompanyPermissions.cleanning_management
                ) && this.permissionChecker("cleanning-view"),
            },
          ],
          rules: true,
        },
        {
          icon: "mdi-cog",
          title: this.$t("settings"),
          children: [
            {
              icon: "mdi-account-multiple",
              title: this.$t("users"),
              link: "/userscontroller",
              rules: this.isAdmin,
            },
            {
              icon: "mdi-archive-arrow-down",
              title: this.$t("beginning"),
              link: "/beginning",
              rules: this.permissionChecker([
                "beginningbalance-view",
                "beginningstore-view",
              ]),
            },
            {
              icon: "",
              title: this.$t("dbsettings"),
              link: "/database/settings",
              rules: this.isAdmin,
            },
            {
              icon: "",
              title: this.$t("system_tools"),
              link: "/database/systemtools",
              rules: this.isAdmin,
            },
          ],
          rules:
            this.permissionChecker([
              "beginningbalance-view",
              "beginningstore-view",
            ]) || this.isAdmin,
        },
      ];

      return this.filterMenu(originalMenu);
    },
  },
  mounted() {
    this.loadDefaults();
  },
};
</script>

<style lang="scss" scoped>
.active-link {
  background-color: var(
    --v-secondaryBlue-base
  ); /* Highlight background for active links */
  color: white; /* Keep the text color the same for active links */
}
::v-deep
  .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  margin: 0;
  min-width: 30px;
}
// .group-open {
//   background-color: rgba(
//     25,
//     118,
//     210,
//     0.1
//   ); /* Background color for open groups */
// }
// .v-list-item--active .v-list-item__content .v-list-item__title {
//   color: inherit; /* Keep the text color the same for active links */
// }
</style>
